import React from 'react';
import ReactDOM from 'react-dom';
import { IdentityContextProvider, useIdentityContext } from 'react-netlify-identity';
import './index.css';

const AuthenticatedApp = React.lazy(() => import('./authenticated-app'));
const UnauthenticatedApp = React.lazy(() => import('./unauthenticated-app'));

const Auth = () => {
  const { isLoggedIn } = useIdentityContext();

  const App = isLoggedIn ? AuthenticatedApp : UnauthenticatedApp;

  return (
    <React.Suspense fallback={<div>Loading</div>}>
      <App />
    </React.Suspense>
  )
};

ReactDOM.render(
  <IdentityContextProvider url="https://jotrly.com">
    <Auth />
  </IdentityContextProvider>,
  document.querySelector('#app')
);
